import React, { useState } from "react"
import Copyright from "./Copyright"
import LinkRow from "./LinkRow"
import { ILinkGroupType, LINK_GROUPS } from "./LINKS"
import './style/LinkTree.css'

export default function Body()
{
  const
    initLinksVisible: boolean[] = [],
    [ linksVisible, setLinksVisible ] = useState(initLinksVisible),
    getSelectedLinksVisible = (i: number) => i < linksVisible.length && linksVisible[i],
    setSelectedLinksVisible = ( selection: number, visible: boolean ) =>
    {
      /**
       * if visible is false, this sets the boolean at index `selection` to false.
       * if visible is true, this sets the boolean at index `selection` to true, and all others to false.
       */
      let newLinksVisible: boolean[] = new Array(linksVisible.length)
      newLinksVisible.forEach( ( link, i ) =>
      {
        newLinksVisible[i] = visible ? false : linksVisible[i]
      })
      newLinksVisible[selection] = visible
      setLinksVisible(newLinksVisible)
    }

  return (
    <div className="app-body">        
      {LINK_GROUPS.map( (rowInfo: ILinkGroupType, i: number) =>
        <LinkRow
          key={ i }
          title={ rowInfo.title ? rowInfo.title : '' }
          links={ rowInfo.links }
          linksVisible={ rowInfo.title ? () => getSelectedLinksVisible(i) : () => true }
          hideLinks={ () => setSelectedLinksVisible(i, false)}
          showLinks={ () => setSelectedLinksVisible(i, true)}
          />
        ) }
      <Copyright />
      {/* <ReleasesButton /> */}
      {/* {linkElements} */}
    </div>
  )
}
  
// eslint-disable-next-line
const getReleaseEmbedElements:() => JSX.Element[] = () =>
{
  // TODO: url encode links
  // TODO: add more releases
  const releases = [{title:'cherry trees', link: 'https%3A%2F%2Fsong.link%2Fi%2F1536224709'}]

  return releases.map( ( { title, link } ) => 
    <iframe
      width="100%" height="52"
      title={ title }
      src={ `https://embed.song.link/?url=${link}&theme=dark` }
      frameBorder="0"
      allowFullScreen
      sandbox="allow-same-origin allow-scripts allow-presentation 
               allow-popups allow-popups-to-escape-sandbox"
    ></iframe>
  )
}

/*
const DroptoberButton: () => JSX.Element = () =>
{
  const droptober = { title: "DROPTOBER", links: [ { type: APPLE, link: DROPTOBER_APPLE_URL }, { type: SPOTIFY, link: DROPTOBER_SPOTIFY_URL } ] }
  return (
    <ExpandableButton
      className="droptober-button"
      text={ droptober.title }
      short_title='🎃'
      children={ droptober.links.map( ( { type, link } ) => (
        <a className='playlist-link-button border' href={ link }>
          <FontAwesomeIcon icon={ type === SPOTIFY ? faSpotify : faMusic } />
        </a>
      )) }
    />
  )
}
*/
