import React from 'react'
export default function TidalIcon( )
{
  return ( 
    <i className="tidal-icon" data-v-6148c111="" >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M12 5.6L8.3 9.3 4.6 5.6 0.9 9.3 4.6 13 8.3 9.3 12 13 8.3 16.7 12 20.4 15.7 16.7 12 13 15.7 9.3 12 5.6z" />
        <path d="M16.8 6.7H22V11.9H16.8z" transform="rotate(-45 19.4 9.3)" />
      </svg>
    </i >
  )
}
