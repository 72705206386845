import React from 'react'

export default function Header()
{
  return (
    <div className='header'>
      <img 
        // src="https://d1fdloi71mui9q.cloudfront.net/GxkqDXfATfywCTZIJoPi_s96kODveZVeUfe0M"
        src="/images/profile_minor_field_4MB.png"
        alt="profile or header"
        className="header-image"
        ></img>
      <div className='title'> brew </div>
    </div>
  )
}
