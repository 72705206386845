import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSoundcloud, faInstagram, faTwitter, faSpotify, faYoutube, faApple } from '@fortawesome/free-brands-svg-icons'
import './style/SocialRow.css'
import { ESocialRowIconType } from './LINKS'
import TidalIcon from './TidalIcon'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'

interface ISocialRowIconProps
{
  type: ESocialRowIconType
  href: string
}

export function SocialRowIcon( { type, href }: ISocialRowIconProps )
{
  // eslint-disable-next-line
  const { TWITTER, INSTAGRAM, SOUNDCLOUD, YOUTUBE, APPLE, SPOTIFY, TIDAL, MORE } = ESocialRowIconType
  const typeToIconMap: Map<ESocialRowIconType, JSX.Element> = new Map( [
    [ TWITTER,    <FontAwesomeIcon icon={ faTwitter } /> ],
    [ INSTAGRAM,  <FontAwesomeIcon icon={ faInstagram } /> ],
    [ SOUNDCLOUD, <FontAwesomeIcon icon={ faSoundcloud } /> ],
    [ YOUTUBE, <FontAwesomeIcon icon={ faYoutube } /> ],
    [ APPLE, <FontAwesomeIcon icon={ faApple } /> ],
    [ SPOTIFY, <FontAwesomeIcon icon={ faSpotify } /> ],
    [ TIDAL, <TidalIcon /> ],
    [ MORE, <FontAwesomeIcon icon={ faEllipsisH } /> ],
  ])

  return (
    <div className='social-row-icon'>
      <a href={href}>
        <p className={`border ${type}`}>
          { typeToIconMap.get(type) }
        </p>
      </a>
    </div>
  )
}
