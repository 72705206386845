import React from 'react'
import { LINK_OBJ } from './LINKS'
import { SocialRowIcon } from './SocialRow'

interface ILinkRowProps
{
  title?: string,
  links: LINK_OBJ[],
  linksVisible: () => boolean,
  showLinks: () => void,
  hideLinks: () => void,
}
export default function LinkRow( props: ILinkRowProps ) 
{
  const
    { title, links, linksVisible, showLinks, hideLinks } = props,
    linksHidden = () => !linksVisible(),

    toggleHidden = () =>
    {
      if ( linksHidden() ) showLinks()
      else hideLinks()
    }

  let titleClassName = `row-title border link-reveal-button`
  if (!linksHidden())
  {
    titleClassName += ` selected`
  }

  return (
    <div className="link-row-wrapper">
      { title
        ? <p className={ titleClassName } onClick={ toggleHidden }>{ title }</p>
        : '' }
      <div className={ `link-row row ${linksHidden() ? 'hidden' : ''} ${title ? 'has-title' : ''}` }>
        {links.map(( link, i ) => <SocialRowIcon key={ i } type={ link.type } href={ link.href }/> )}
      </div>
    </div>
  )
}
