import React from 'react';

export default function Copyright() 
{
  return(
    <p className='copyright'>
      <i className="fa fa-copyright" />
      © 2020 all rights reserved. fresh brewed llc
    </p>
  )
}
