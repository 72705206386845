export enum ESocialRowIconType
{
  TWITTER="TWITTER",
  INSTAGRAM="INSTAGRAM",
  SOUNDCLOUD="SOUNDCLOUD",
  APPLE="APPLE",
  SPOTIFY="SPOTIFY",
  YOUTUBE="YOUTUBE",
  TIDAL="TIDAL",
  MORE="MORE",
}

// eslint-disable-next-line
const { TWITTER, INSTAGRAM, SOUNDCLOUD,
        APPLE, SPOTIFY, YOUTUBE, TIDAL, MORE } = ESocialRowIconType

export interface LINK_OBJ
{
  type: ESocialRowIconType
  href: string
}

export interface ILinkGroupType
{
  title?: string,
  links: LINK_OBJ[],
}

const LISTENTOBREW_LINKS: LINK_OBJ[] = [
  { type: APPLE, href: "https://music.apple.com/us/playlist/listentobrew/pl.u-PDb44l4sN68pVq" },
  { type: SPOTIFY, href: "https://open.spotify.com/playlist/50m7mqFkrWZBdlv5HvgnPL?si=rbwfDfowRH65c75o4Ui7iQ" },
  { type: YOUTUBE, href: "https://youtube.com/playlist?list=PLlufDMiEMmmgV3RoYTPJ4CI5cOcK1RuJB"}
]

const CRY_TO_ME_LINKS: LINK_OBJ[] = [ 
  {  type: APPLE, href: "https://geo.music.apple.com/us/album/_/1541202699?i=1541202700&mt=1&app=music&ls=1&at=1000lHKX" },
  {  type: SPOTIFY, href: "https://open.spotify.com/track/4PFI4Vyud3nQoj3krW4wWy?si=RPyF3IeCSHOHfmYY-xWFRw" },
  {  type: MORE, href: "https://song.link/us/i/1541202700" },
]

const DROPTOBER_LINKS: LINK_OBJ[] = [
  { type: APPLE, href: 'https://geo.music.apple.com/us/playlist/droptober/pl.u-6mo44PmfP1JXkd' },
  { type: SPOTIFY, href: 'https://open.spotify.com/playlist/3V0ULZtvgZjzUqIqUJjVaU?si=leW7NAWWSd6KeIYjvs4DPw' },
  // TODO: MAKE YOUTUBE / TIDAL PLAYLISTS
]

const PROFILE_LINKS: LINK_OBJ[] = [
  { type: INSTAGRAM, href: 'https://www.instagram.com/listentobrew/' },
  { type: TWITTER, href: 'https://twitter.com/drewdiezel' },
  { type: YOUTUBE, href: 'https://youtube.com/c/drewmcarthur' },
]

const PROFILE_LINKS_2: LINK_OBJ[] = [
  { type: APPLE, href: 'https://music.apple.com/us/artist/brew/1536110299' },
  { type: SPOTIFY, href: 'https://open.spotify.com/artist/3BGIZ4wLaUFp10QKx3svrk?si=BlZz6n12SGKK8E1SPXweFg' },
  { type: SOUNDCLOUD, href: 'https://soundcloud.com/lstn2brew', },
]

export const WHEN_IT_RAINED_LINKS: LINK_OBJ[] = [
  { type: APPLE, href: "https://music.apple.com/us/album/when-it-rained/1536528463?i=1536528464LE" },
  { type: SPOTIFY, href: "https://open.spotify.com/track/2bESb3Bo3fvzNzwBf3ho6Y?si=PKq6KuXnSfKkRajQytjcfw" },
  { type: SOUNDCLOUD, href:"https://soundcloud.com/lstn2brew/when-it-rained-v8-landr-master" },
  { type: YOUTUBE, href: "https://www.youtube.com/watch?v=8wjPBMJtQbo" },
  { type: TIDAL, href: "https://listen.tidal.com/track/159205548" },
  { type: MORE, href: "https://song.link/us/i/1536528464" },
]

export const CHERRY_TREES_LINKS: LINK_OBJ[] = [
  { type: APPLE, href: 'https://geo.music.apple.com/us/album/_/1536224707?i=1536224709&mt=1&app=music&at=1000lHKX '},
  { type: SPOTIFY, href: 'https://open.spotify.com/track/4b6L9VnZric59cvlggqIRI' },
  { type: YOUTUBE, href: 'https://www.youtube.com/watch?v=nQfzF72yUsE' },
  { type: SOUNDCLOUD, href: 'https://soundcloud.com/lstn2brew/cherry-trees-v6' },
  { type: MORE, href: "https://song.link/us/i/1536224709" },
]

const YKTV_LINKS: LINK_OBJ[] = [
  { type: APPLE, href: 'https://music.apple.com/us/album/y-k-t-v-feat-brew/1526778069?i=1526778074'},
  { type: SPOTIFY, href: 'https://open.spotify.com/track/6WQgDPGWg4K6waHMhzM1Gj?si=DMDaGAkmQKiRsWTiYBxOuw' },
  { type: YOUTUBE, href: 'https://www.youtube.com/watch?v=jJ-9VpXwEq8' },
]

const LOUIS_V_LINKS: LINK_OBJ[] = [
  { type: APPLE, href: 'https://music.apple.com/us/album/louis-v-feat-brew/1495996320?i=1495996323' },
  { type: SPOTIFY, href: 'https://open.spotify.com/track/5chBP62Awzh9rS2VEJM3yP?si=-KVWvIMNRty2gf--1F1fhQ' },
  { type: SOUNDCLOUD, href: 'https://soundcloud.com/lstn2brew/louisv-v2' },
]

export const LINK_GROUPS: ILinkGroupType[] = [
  { links: PROFILE_LINKS },
  { links: PROFILE_LINKS_2 },
  { title: 'listentobrew', links: LISTENTOBREW_LINKS },
  { title: 'CRY TO ME', links: CRY_TO_ME_LINKS },
  { title: 'WHEN IT RAINED', links: WHEN_IT_RAINED_LINKS },
  { title: 'DROPTOBER 🎃', links: DROPTOBER_LINKS },
  { title: 'CHERRY TREES', links: CHERRY_TREES_LINKS },
  { title: 'YKTV', links: YKTV_LINKS },
  { title: 'LOUIS V', links: LOUIS_V_LINKS },
]
